import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Operation from './Operation'
import { OperationShape } from '../mainview/types'
import './OperationsBox.css'
import { sortOperations } from '../common'

const OperationsBox = ({ operations, lotSize }) => {
  if (!operations?.length) {
    return ''
  }
  const sortedOps = sortOperations(operations)
  return (
    <Box className="OperationsBox">
      {sortedOps.map((op) => (
        <Operation key={op.jrk} lotSize={lotSize} op={op} />
      ))}
    </Box>
  )
}

OperationsBox.propTypes = {
  operations: PropTypes.arrayOf(PropTypes.shape(OperationShape)).isRequired,
  lotSize: PropTypes.number.isRequired,
}

export default OperationsBox
