import React, { useCallback, useEffect, useRef, useState } from 'react'
import './MainView.css'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Autorenew, Close, Search } from '@material-ui/icons'
import {
  selectRows,
  getTopLevelOrdersPage,
  selectIsLoading,
  selectPagination,
  setRowsPerPage,
  setPage,
  setFilterFields,
  selectFilterFields,
  selectError,
  getOrderById,
  setFilterButtons,
} from './mainViewSlice'
import ProgressBar from '../progressBar/ProgressBar'
import { OrderShape } from './types'
import OperationsBox from '../operations/OperationsBox'
import { dateRangeQuery, FIELDS_TO_QUERY, fromDateRangeQuery, operationsQuery, statusQuery, textQuery } from './fields'
import { ShowSubordersButton, SwapVertButton } from './buttons'
import { CustomPaginationActions } from './CustomPaginationActions'
import { useFields } from '../common'
import { openWin } from '../common/openwindow'

/**
 *
 * @param {OrderShape} row
 * @param {OrderShape|null} topLevelRow - the master order where to draw some values from
 * @param style - even or odd
 * @param level - nestedness
 * @param collapsedClassName
 * @param {Function} toggleColumn - action to toggle column
 * @param current -- if searched by order ID
 * @param selectedRows - array of selected row (order) IDs (top level orders only)
 * @param toggleRowSelectedState - change row's selected state (top level orders only)
 * @returns {JSX.Element}
 * @constructor
 */
const OrderRow = ({
  row,
  topLevelRow = null,
  style,
  level = 1,
  collapsedClassName,
  toggleColumn,
  current,
  selectedRows,
  toggleRowSelectedState,
}) => {
  const hasChildren = row.suborders?.length
  const [showChildren, setShowChildren] = useState(hasChildren && !!current)
  const toggleChildren = useCallback(() => hasChildren && setShowChildren(!showChildren), [hasChildren, showChildren])
  const isSelected = selectedRows?.includes(row.sernr)

  return (
    <>
      <TableRow
        key={row.sernr}
        onClick={() => toggleRowSelectedState?.(row.sernr)}
        className={`TableRow ${style} level-${level}
                ${isSelected ? 'selected' : ''}
                ${row.sernr === parseInt(current, 10) ? 'current' : ''}`}
      >
        <TableCell scope="row" className={`TableCell order-id ${hasChildren ? '' : 'no-children'}`}>
          {hasChildren ? <ShowSubordersButton onClick={toggleChildren} show={showChildren} /> : ''}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={() => openWin({ sernr: row.sernr })}>
            {row.sernr}
          </a>
        </TableCell>
        <TableCell className={collapsedClassName('article')}>
          <span>{row.article}</span>
        </TableCell>
        <TableCell className={collapsedClassName('part_desc')}>
          <span>{row.part_desc}</span>
        </TableCell>
        <TableCell className={collapsedClassName('prod_type')}>
          <span>{row.prod_type}</span>
        </TableCell>
        <TableCell className={collapsedClassName('end_client_name')}>
          <span>{row.end_client_name}</span>
        </TableCell>
        <TableCell className={collapsedClassName('mt_sernr')} align="center">
          <span>{row.mt_sernr || topLevelRow?.mt_sernr}</span>
        </TableCell>
        <TableCell className={collapsedClassName('planned_delivery')}>
          <span>{row.planned_delivery || topLevelRow?.planned_delivery}</span>
        </TableCell>
        <TableCell className={collapsedClassName('need_date')}>
          <span>{row.need_date}</span>
        </TableCell>
        <TableCell className={collapsedClassName('produced')} align="right">
          <span>
            {row.received || 0} / {row.lot_size || 0}
          </span>
        </TableCell>
        <TableCell className={collapsedClassName('to_produce')} align="right">
          <span>
            {/* sometimes `received` can exceed `lot size` */}
            {Math.max(0, (row.lot_size || 0) - (row.received || 0))}
          </span>
        </TableCell>
        <TableCell className={collapsedClassName('progress')}>
          <ProgressBar
            row={row}
            toggleOperationsColumn={(e) => {
              e.preventDefault()
              e.stopPropagation()
              toggleColumn('operations')
            }}
          />
        </TableCell>
        <TableCell className={collapsedClassName('operations')}>
          <OperationsBox operations={row.reportings_json} lotSize={row.lot_size} />
        </TableCell>
      </TableRow>
      {showChildren
        ? row.suborders.map((subrow) => (
            <OrderRow
              key={subrow.sernr}
              style={style}
              row={subrow}
              topLevelRow={topLevelRow ?? row}
              level={level + 1}
              collapsedClassName={collapsedClassName}
              toggleColumn={toggleColumn}
              current={current}
              selectedRows={null}
              toggleRowSelectedState={null}
            />
          ))
        : null}
    </>
  )
}

OrderRow.propTypes = {
  row: PropTypes.shape(OrderShape).isRequired,
  topLevelRow: PropTypes.shape(OrderShape),
  style: PropTypes.string.isRequired,
  level: PropTypes.number,
  collapsedClassName: PropTypes.func.isRequired,
  toggleColumn: PropTypes.func.isRequired,
  current: PropTypes.number,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  toggleRowSelectedState: PropTypes.func,
}
OrderRow.defaultProps = {
  level: 1,
  topLevelRow: null,
  current: null,
  selectedRows: null,
  toggleRowSelectedState: null,
}

const CollapsibleColumnHead = ({ name, title, get, toggle }) => (
  <TableCell align="left" className={get(name)}>
    <span className="header-container">
      {title}
      <SwapVertButton onClick={() => toggle(name)} />
    </span>
  </TableCell>
)

CollapsibleColumnHead.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  get: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
}

function MainView() {
  const { t } = useTranslation()
  const FIELDS = useFields()
  const error = useSelector(selectError)
  /** @type {OrderShape[]} */
  const rows = useSelector(selectRows)
  const filterInputs = useSelector(selectFilterFields)
  const { page, count, rowsPerPage } = useSelector(selectPagination)
  const isLoading = useSelector(selectIsLoading)
  const dispatch = useDispatch()
  /**
   * Mapping "column name" => boolean.
   * The operations column is collapsed by default
   */
  const [collapsedCols, setCollapsedCols] = useState({
    operations: true,
  })
  const [selectedRows, setSelectedRows] = useState([])

  /**
   * Filters-related stuff
   */
  const [applyFiltersTimeout, setApplyFiltersTimeout] = useState(null)
  const applyFiltersPromise = useRef(null)

  /** @type {OrderShape[]} */
  const arrayOfOrders = []
  const [selectedOrders, setSelectedOrders] = useState(arrayOfOrders)
  const [orderIdSearch, setOrderIdSearch] = useState(null)
  const [showOrderIdSearchModal, setShowOrderIdSearchModal] = useState(false)
  const orderIdInputRef = useRef()
  const [orderIdInputError, setOrderIdInputError] = useState(false)

  useEffect(() => dispatch(getTopLevelOrdersPage()), [dispatch])
  useEffect(
    () => setSelectedOrders(selectedRows.length ? rows?.filter((row) => selectedRows.includes(row.sernr)) : rows),
    [selectedRows, rows],
  )

  const applyFilters = useCallback(
    (e) => {
      if (applyFiltersTimeout) {
        clearTimeout(applyFiltersTimeout)
      }
      if (applyFiltersPromise.current) {
        applyFiltersPromise.current.abort()
        applyFiltersPromise.current = null
      }
      if (e.key === 'Enter') {
        applyFiltersPromise.current = dispatch(getTopLevelOrdersPage())
        return
      }
      let { value } = e.target
      if (e.key === 'Escape') {
        value = ''
        e.target.value = value
      }

      dispatch(setFilterFields({ [e.target.name]: value }))
      const timeout = setTimeout(() => {
        applyFiltersPromise.current = dispatch(getTopLevelOrdersPage())
        setApplyFiltersTimeout(null)
      }, 500)
      setApplyFiltersTimeout(timeout)
    },
    [dispatch, applyFiltersTimeout],
  )

  const applyFiltersStatus = useCallback(
    (e) => {
      const { value } = e.target
      dispatch(setFilterFields({ progress: value }))

      dispatch(getTopLevelOrdersPage())
    },
    [dispatch],
  )

  const applyFiltersDate = useCallback(
    (e) => {
      dispatch(setFilterButtons()) // reset the filter buttons in header.

      const { name, value } = e.target
      const [fieldName, rangePoint] = name.split('.') // date.from => [date, from]
      const previousFilterValue = fromDateRangeQuery(filterInputs[fieldName])
      const { from, to } = { ...previousFilterValue, [rangePoint]: value }

      const newFilters = { [fieldName]: `${from}:${to}` }
      dispatch(setFilterFields(newFilters))
      dispatch(getTopLevelOrdersPage())
    },
    [dispatch, filterInputs],
  )

  const collapsedClassName = useCallback((name) => (collapsedCols[name] ? 'collapsed' : ''), [collapsedCols])
  const toggleColumn = useCallback(
    (name) =>
      setCollapsedCols({
        ...collapsedCols,
        [name]: !collapsedCols[name],
      }),
    [collapsedCols],
  )

  const toggleRowSelectedState = useCallback(
    (sernr) => {
      if (selectedRows.includes(sernr)) {
        setSelectedRows(selectedRows.filter((rowId) => rowId !== sernr))
      } else {
        setSelectedRows([...selectedRows, sernr])
      }
    },
    [selectedRows],
  )

  const applyOrderIdSearch = useCallback(() => {
    setOrderIdInputError(false)
    const orderId = orderIdInputRef.current.value
    if (!orderId) {
      return false
    }
    if (!/^\d+$/.test(orderId)) {
      setOrderIdInputError(true)
      return false
    }
    setOrderIdSearch(parseInt(orderId, 10))
    setShowOrderIdSearchModal(false)
    dispatch(getOrderById({ orderId }))
    return true
  }, [dispatch, orderIdInputRef])

  const cancelOrderIdSearch = useCallback(() => {
    setShowOrderIdSearchModal(false)
    setOrderIdInputError(false)
    setOrderIdSearch(null)
  }, [])

  return (
    <>
      <Table className="MainView" size="small">
        <TableHead>
          <TableRow className="TableRow no-border">
            {Object.entries(FIELDS).map(([fieldName, fieldTitle]) =>
              fieldName === 'sernr' ? (
                <TableCell>
                  <span className="header-container">
                    {t('Product order')}
                    <Button
                      className="OrderIDSearchButton"
                      title={t('Find order by exact ID')}
                      color="secondary"
                      onClick={() => setShowOrderIdSearchModal(true)}
                    >
                      <Search />
                    </Button>
                  </span>
                </TableCell>
              ) : (
                <CollapsibleColumnHead
                  name={fieldName}
                  title={fieldTitle}
                  get={collapsedClassName}
                  toggle={toggleColumn}
                />
              ),
            )}
          </TableRow>
          <TableRow className="TableRow quickfilters">
            {Object.keys(FIELDS).map((name) => {
              const filterFunction = FIELDS_TO_QUERY[name]
              if (filterFunction === undefined) {
                return (
                  <TableCell component="td" align="left" className={collapsedClassName(name)}>
                    <div />
                  </TableCell>
                )
              }
              const value = filterInputs[name]
              let dateFrom = ''
              let dateTo = ''
              if (filterFunction === dateRangeQuery) {
                ;({ from: dateFrom, to: dateTo } = fromDateRangeQuery(value))
              }
              return (
                <TableCell key={name} className={collapsedClassName(name)} component="td">
                  {filterFunction === dateRangeQuery && (
                    <div>
                      <input
                        type="date"
                        name={`${name}.from`}
                        title={t('Beginning of date range, inclusive')}
                        value={dateFrom || ''} // strangely, defaultValue doesn't work here.
                        size={10}
                        onChange={applyFiltersDate}
                      />
                      <br />
                      <input
                        type="date"
                        name={`${name}.to`}
                        title={t('End of date range, inclusive')}
                        value={dateTo || ''} // strangely, defaultValue doesn't work here.
                        size={10}
                        onChange={applyFiltersDate}
                      />
                    </div>
                  )}
                  {(filterFunction === textQuery || filterFunction === operationsQuery) && (
                    <>
                      <input type="text" name={name} defaultValue={value} size={16} onKeyUp={applyFilters} />
                      <div>{t('Enter to apply, Esc to clear')}</div>
                    </>
                  )}
                  {filterFunction === statusQuery && (
                    <FormControl>
                      <Select
                        displayEmpty
                        labelId="filter-risk-level-label"
                        id="filter-risk-level"
                        value={filterInputs.progress || ''}
                        onChange={applyFiltersStatus}
                      >
                        <MenuItem value="">{t('All')}</MenuItem>
                        <MenuItem value="problem">{t('Risk')}</MenuItem>
                        <MenuItem value="not started">{t('Not started')}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {name === 'mt_sernr' && (
                    <>
                      <input
                        type="number"
                        name={name}
                        defaultValue={value}
                        size={10}
                        onKeyUp={applyFilters}
                        title={t('Match by pattern, only top level orders')}
                      />
                      <div>{t('Enter to apply, Esc to clear')}</div>
                    </>
                  )}
                  {name === 'sernr' &&
                    (orderIdSearch === null ? (
                      <>
                        <input
                          type="number"
                          name={name}
                          defaultValue={value}
                          size={10}
                          onKeyUp={applyFilters}
                          title={t('Match by pattern, only top level orders')}
                        />
                        <div>{t('Enter to apply, Esc to clear')}</div>
                      </>
                    ) : (
                      <>
                        <Button
                          color="primary"
                          variant="contained"
                          title={t('Clear and reload the table')}
                          onClick={() => {
                            setOrderIdSearch(null)
                            dispatch(getTopLevelOrdersPage())
                          }}
                        >
                          {orderIdSearch} <Close />
                        </Button>
                        <div>{t('Clear and reload')}</div>
                      </>
                    ))}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={1000} align="center">
                <Autorenew className="is-loading" style={{ fontSize: 80 }} />
              </TableCell>
            </TableRow>
          ) : error ? (
            <TableRow>
              <TableCell colSpan={1000} align="center">
                {t('Error')} {error}
              </TableCell>
            </TableRow>
          ) : rows?.length ? (
            rows.map((row, index) => (
              <OrderRow
                key={row.sernr}
                row={row}
                style={index % 2 ? 'odd' : 'even'}
                collapsedClassName={collapsedClassName}
                toggleColumn={toggleColumn}
                current={orderIdSearch}
                selectedRows={selectedRows}
                toggleRowSelectedState={toggleRowSelectedState}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={1000} align="center">
                {t('No rows match the specified conditions.')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          {!isLoading && !error && selectedOrders?.length > 0 && (
            <TableRow>
              <TableCell colSpan={8} />
              <TableCell className={collapsedClassName('produced')} align="right">
                <span className="hide-collapsed">
                  {selectedOrders?.map((o) => o.received).reduce((sum, val) => sum + val, 0)} /{' '}
                  {selectedOrders?.map((o) => o.lot_size).reduce((sum, val) => sum + val, 0)}
                </span>
              </TableCell>
              <TableCell className={collapsedClassName('to_produce')} align="right">
                <span className="hide-collapsed">
                  {selectedOrders?.map((o) => Math.max(0, o.lot_size - o.received)).reduce((sum, val) => sum + val, 0)}
                </span>
              </TableCell>
              <TableCell colSpan={1000} />
            </TableRow>
          )}
          <TableRow>
            <TablePagination
              count={count}
              onChangePage={(e, n) => {
                dispatch(setPage(n + 1))
                dispatch(getTopLevelOrdersPage())
              }}
              onChangeRowsPerPage={(e) => {
                dispatch(setRowsPerPage(e.target.value))
                dispatch(getTopLevelOrdersPage())
              }}
              page={page - 1} // zero-based number
              rowsPerPage={rowsPerPage}
              ActionsComponent={CustomPaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Dialog open={showOrderIdSearchModal}>
        <DialogTitle id="simple-dialog-title">{t('Search by Order ID')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Enter a numeric order ID')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            error={orderIdInputError}
            placeholder={t('Order ID')}
            type="text"
            fullWidth
            defaultValue={orderIdSearch}
            inputRef={orderIdInputRef}
            label={orderIdInputError ? t('Error') : ''}
            helperText={orderIdInputError ? t('This value must be a number') : ''}
            inputProps={{
              onKeyUp: (e) => {
                if (e.key === 'Escape') {
                  cancelOrderIdSearch()
                }
                if (e.key === 'Enter') {
                  applyOrderIdSearch()
                }
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={applyOrderIdSearch} color="primary" variant="contained">
            {t('Find')}
          </Button>
          <Button onClick={cancelOrderIdSearch} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MainView
