import { Endpoints, getApiUrl, ITEMS_PER_PAGE } from './config'

/**
 * @typedef {Object} Response
 * @property {Number} status
 * @property data
 */

/**
 * Make a request to the endpoint given by url.
 *
 * Note: as we only have one endpoint, we don't need sophisticated stuff.
 *
 * @param {String} endpoint - one of config/Endpoints
 * @param {String} url - can override the default base url
 * @param {String} method
 * @param {String} token
 * @param {Object} query - an Object of query parameters
 * @param {AbortSignal} signal - used to cancel the request
 * @param options - additional options to fetch()
 * @returns {Promise<Response>}
 */
export const request = async ({
  endpoint,
  url = '',
  method = '',
  query = null,
  token = '',
  options = null,
  signal,
}) => {
  const requestUrl = getApiUrl({ endpoint, url, query, token })

  const response = await fetch(requestUrl, {
    method: method || 'GET',
    signal,
    ...(options || {}),
  })

  let data
  try {
    data = await response.json()
  } catch (e) {
    const ex = new Error('Failed to parse response as JSON')
    ex.response = response
    ex.url = requestUrl
    throw ex
  }

  return {
    status: response.status,
    data,
  }
}

/**
 * @param {Number} page - a data page
 * @param {Number} rowsPerPage
 * @param {Object} query - an Object of query parameters (filters)
 * @param {Boolean} count - whether to fetch only the number of rows matched
 * @param {AbortSignal} signal - used to cancel the request
 * @returns {Promise<Response>}
 */
export const ordersRequest = async ({ page = 0, rowsPerPage = 0, count = false, query = null, signal }) => {
  const queryLocal = query ? { ...query } : {}
  if (!count && page > 0) {
    queryLocal.limit = rowsPerPage || ITEMS_PER_PAGE
    queryLocal.offset = (page - 1) * queryLocal.limit
  }
  if (count) {
    queryLocal.select = 'count(1)'
  }

  return request({ endpoint: Endpoints.orders, query: queryLocal, signal })
}

/**
 * does not return any data about top level nodes
 *
 * @typedef {Object} Node
 * @property {Number} sernr
 * @property {Number} parent
 * @property {Number} master
 *
 * @typedef {Response} NodeResponse
 * @property {Array<Node>} data
 *
 * @param {Object} query - an Object of query parameters (filters)
 * @param {AbortSignal} signal - used to cancel the request
 * @returns {Promise<NodeResponse>}
 */
export const treeRequest = async ({ query = null, signal }) => request({ endpoint: Endpoints.tree, query, signal })

/**
 * Same as tree request, but returns also top level nodes
 *
 * @typedef {Object} Node
 * @property {Number} sernr
 * @property {Number} parent
 * @property {Number} master
 *
 * @typedef {Response} NodeResponse
 * @property {Array<Node>} data
 *
 * @param {Object} query - an Object of query parameters (filters)
 * @param {AbortSignal} signal - used to cancel the request
 * @returns {Promise<NodeResponse>}
 */
export const treeWithTopLevelRequest = async ({ query = null, signal }) =>
  request({ endpoint: Endpoints.treeWithTopLevel, query, signal })
