/**
 * @param  {OperationShape[]} operations
 * @return {OperationShape[]}
 */
export const sortOperations = (operations) => [...operations].sort((a, b) => (a.jrk > b.jrk ? 1 : -1))

/**
 * @typedef {Object} ProblemObj
 * @property {Boolean} isLate
 * @property {Number} missingMaterial
 * @property {Number} missingMaterialsCount
 *
 * @typedef {Object} OpFeatures
 * @property {Number} allMaterialsCount
 * @property {Boolean} problem
 * @property {ProblemObj} problemObj
 * @property {Number} reported

 * @typedef {ProblemObj} ProblemObjWithOperation - used for displaying the risk sign in the ProgressBar (needs op code)
 * @property {String} operation - the operation code
 *
 */

/**
 *
 * @param {OperationShape} op
 * @returns {OpFeatures}
 */
export const getOpFeatures = (op) => {
  const reported = (op.wt_qty ?? 0) + (op.scrap ?? 0)
  const isLate = op.op_planned_start && op.op_planned_start < new Date().toISOString().split('T')[0]
  const allMaterialsCount = op.materials?.length ?? 0

  const problemObj = {}
  if (!op.operation_finished) {
    if (isLate) {
      problemObj.isLate = true
    }
    if (op.problem && allMaterialsCount > 0) {
      const missing = op.materials.filter((m) => m.inv_qty < m.needed_material_qty)
      const missingMaterialsCount = missing.length
      if (missingMaterialsCount > 0) {
        problemObj.missingMaterialsCount = missingMaterialsCount
        if (missingMaterialsCount === 1) {
          problemObj.missingMaterial = missing[0].material
        }
      }
    }
  }

  return {
    reported,
    allMaterialsCount,
    problem: Object.keys(problemObj).length > 0,
    problemObj,
  }
}

/**
 * Whether operation has any kind of problem
 *
 * @param {OperationShape[]} ops
 * @returns {ProblemObjWithOperation[]}
 */
export const getProblemOps = (ops) =>
  ops
    .map((op) => {
      const features = getOpFeatures(op)
      if (features.problem) {
        return { ...features.problemObj, operation: op.operation }
      }
      return null
    })
    .filter((prob) => !!prob)
