/**
 * Calculates individual operations progress.
 *
 * Individual Operation progress is evaluated as follows (in that order):
 * * 1 (100%) if operation_finished is true (all other parameters are ignored.)
 * * 0 (100%) if operation_finished is not true and started is not true
 * * x, 0 <= x <= 0.99 (sic) where
 *    x = min(0.99, time_spent/planned_time_sec)
 *
 * Examples:
 *   {started: false, operation_finished: true, planned_time_sec: 600, time_spent: null}
 *   => progress = 1
 *   {started: false, operation_finished: false, planned_time_sec: 600, time_spent: 0}
 *   => progress = 0
 *   {started: true, operation_finished: false, planned_time_sec: 600, time_spent: 30}
 *   => progress = 0.05
 *   {started: true, operation_finished: false, planned_time_sec: 600, time_spent: 300000}
 *   => progress = 0.99
 *
 * @param {OperationShape} operation
 * @returns {Number} - a float between 0 and 1.
 */
export const calculateOperationProgress = (operation) => {
  let progress
  if (operation.operation_finished) {
    progress = 1
  } else if (operation.started) {
    const timeRatio = operation.time_spent / operation.planned_time_sec
    progress = Math.min(0.99, timeRatio || 0)
  } else {
    progress = 0
  }
  return progress
}

/**
 * @typedef {Object} ProgressResult
 * @property {Number} progress - a float between 0 and 1.
 * @property {Number} totalPlannedTime - the sum of all operations' planned times
 * @property {Number} totalSpentTime - the sum of all operations' spent times
 */

/**
 * Calculates aggregate progress over operations.
 *
 * Aggregate progress equals to a weighted sum of individual operation progress
 *   divided by the total planned time, where the weight of an individual operation
 *   equals to its planned time.
 *
 * Example: given these operations
 * [
 *   {started: false, operation_finished: true, planned_time_sec: 100, time_spent: null}, // 1
 *   {started: false, operation_finished: false, planned_time_sec: 300, time_spent: 0}, // 0
 *   {started: true, operation_finished: false, planned_time_sec: 600, time_spent: 30}, // 0.05
 *   {started: true, operation_finished: false, planned_time_sec: 1000, time_spent: 300000}, // 0.99
 * ]
 * the aggregate progress equals to (1*100 + 0*300 + 0.05*600 + 0.99*1000) / (100 + 300 + 600 + 1000), which is
 *   1120/2000 = 0.56 = 56%
 *
 * @param {OperationShape[]} operations
 * @returns {ProgressResult}
 */
export const calculateAggregateProgress = (operations) => {
  let totalPlannedTime = 0
  let totalSpentTime = 0
  let weightedSum = 0
  operations.forEach((op) => {
    // some orders have planned_time_sec=null, try to use time_spent,
    // and if that is not available either, put some random number
    const plannedTime = op.planned_time_sec || op.time_spent || 100
    totalPlannedTime += plannedTime
    totalSpentTime += op.time_spent || 0
    weightedSum += calculateOperationProgress(op) * plannedTime
  })
  return {
    progress: weightedSum / totalPlannedTime,
    totalPlannedTime,
    totalSpentTime,
  }
}

/**
 *
 * @param {OrderShape} order
 * @returns {OperationShape[]}
 */
export const getNestedOperations = (order) => {
  let operations = order.reportings_json?.length ? [...order.reportings_json] : []
  if (order.suborders?.length) {
    operations = operations.concat(...order.suborders.map((o) => getNestedOperations(o)))
  }
  return operations
}

/**
 * @typedef {ProgressResult} ProgressPercentResult
 * @property {Number} progressPercent
 *
 * @param {OrderShape} order
 * @returns {ProgressPercentResult}
 */
export const calculateOrderProgress = (order) => {
  const allNestedOps = getNestedOperations(order)
  const { progress, totalPlannedTime, totalSpentTime } = calculateAggregateProgress(allNestedOps)
  const aggregateProgress = order.finished ? 1 : progress
  let progressPercent
  if (aggregateProgress === 1) {
    progressPercent = 100
  } else if (aggregateProgress > 0.99) {
    // should never be 100 unless all complete, even if it's over 99.5%
    progressPercent = 99
  } else {
    // do not allow it to be negative
    progressPercent = Math.max(0, Math.round(aggregateProgress * 100))
  }
  return {
    progress,
    progressPercent,
    totalPlannedTime,
    totalSpentTime,
  }
}

/**
 *
 * @param {OrderShape[]} orders
 * @returns {Number[]} IDs of children
 */
export const getAllChildren = (orders) => [].concat(...orders.map((o) => (o.children?.length ? o.children : [])))

export const displayTime = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const p = new Date(seconds * 1000)
  const fmt = new Intl.DateTimeFormat([], { second: '2-digit', minute: '2-digit' })
  return `${hours}:${fmt.format(p)}`
}
