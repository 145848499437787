const isElectronBuild = process.env.REACT_APP_IS_ELECTRON === 'yes'

let config
if (isElectronBuild) {
  // eslint-disable-next-line global-require
  config = require('./config.electron')
} else {
  // eslint-disable-next-line global-require
  config = require('./config.default')
}

const BASE_URL = process.env.REACT_APP_EZIIL_API_URL || `${config.metaHost}${config.metaDb}`
const TOKEN = process.env.REACT_APP_EZIIL_API_TOKEN

export const Endpoints = {
  orders: '/prod_overview_new.ProductionOverviewNew.json',
  tree: '/so_header_connections_recursive.json',
  treeWithTopLevel: '/so_header_connections_w_top_level.json',
}

// preferably one of 10, 25, 50, 100 - as in TablePagination options
export const ITEMS_PER_PAGE = 10

export const getApiUrl = ({ url, endpoint, query, token }) => {
  const queryLocal = query ? { ...query } : {}
  if (token || TOKEN) {
    queryLocal.token = token || TOKEN
  }

  const queryString = new URLSearchParams(queryLocal)

  return `${url || BASE_URL}${endpoint}?${queryString.toString()}`
}
