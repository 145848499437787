import XLSX from 'xlsx'
import { calculateOrderProgress, displayTime } from '../mainview/utils'
import { getOpFeatures, sortOperations } from '../common'

const prefixLevel = (level) => `${Array(level).join('  ')}${level ? '+ ' : ''}`

const justValue = ({ value }) => (value === null ? undefined : value)
const transformStr = ({ value }) => `${value === undefined || value === null ? '' : value}`
const transformSernr = ({ row, value }) => `${prefixLevel(row.level)}${value}`
const transformProgress = ({ progress }) => `${progress.progressPercent}%`
const transformTimeSpent = ({ progress }) => `${displayTime(progress.totalSpentTime)}`
const transformTimePlanned = ({ progress }) => `${displayTime(progress.totalPlannedTime)}`
const transformProduced = ({ row }) => row.received
const transformOrdered = ({ row }) => row.lot_size
const transformToProduce = ({ row }) => Math.max(row.lot_size - row.received, 0)
const transformStatus = ({ row, progress, t }) =>
  row.finished ? t('Finished') : progress.totalSpentTime > 0 ? t('Not finished') : t('Not started')

/**
 * Value to XLS cell transform functions.
 */
const EXPORT_FIELDS = {
  sernr: transformSernr,
  article: transformStr,
  part_desc: transformStr,
  prod_type: transformStr,
  end_client_name: transformStr,
  mt_sernr: transformStr,
  planned_delivery: justValue, // dates are given as text in the API. XLSX can't handle date input.
  planned_end_date: justValue, // dates are given as text in the API. XLSX can't handle date input.
  need_date: justValue, // dates are given as text in the API. XLSX can't handle date input.
  produced: transformProduced,
  ordered: transformOrdered,
  to_produce: transformToProduce,
  status: transformStatus,
  time_spent: transformTimeSpent,
  time_planned: transformTimePlanned,
  progress: transformProgress,
  risk: ({ row, t }) => (row.problem ? t('Risk') : ''),
}

const OP_FIELD_TITLES = {
  operations: ({ t }) => t('Operations'),
  op_reported: ({ t }) => t('Qty Reported'),
  op_required: ({ t }) => t('Qty Required'),
  op_risk: ({ t }) => t('Risk'),
  op_time_spent: ({ t }) => t('Time spent'),
  op_time_planned: ({ t }) => t('Time planned'),
}

/**
 *
 * @type {{}}
 */
const MORE_TITLES = {
  planned_end_date: ({ t }) => t('Planned end date'),
}

/**
 *
 * @param {OperationShape} op
 * @param t
 * @returns {string[]}
 */
const renderOperation = (op, t) => {
  const { reported, problem, problemObj, allMaterialsCount } = getOpFeatures(op)
  const res = [`${op.operation}`, reported, op.wt_qty ?? 0]
  if (problem) {
    res.push(t('Risk'))
  }
  if (problemObj.missingMaterialsCount) {
    res.push(
      problemObj.missingMaterial
        ? t('Material {material} missing').replace('{material}', problemObj.missingMaterial)
        : t('{numMissing} / {numTotal} materials missing')
            .replace('{numMissing}', problemObj.missingMaterialsCount)
            .replace('{numTotal}', allMaterialsCount),
    )
  }
  if (problemObj.isLate) {
    res.push(t('Late'))
  }
  res.push(displayTime(op.time_spent ?? 0))
  res.push(displayTime(op.planned_time_sec ?? 0))
  return res
}

/**
 * @param {OrderShape} row
 * @param {Function} t - translation function
 */
const transformOperations = ({ row, t }) => {
  /** @type {OperationShape[]} */
  const sortedOps = sortOperations(row.reportings_json)
  return sortedOps.map((op) => renderOperation(op, t)).flat()
}

const recurseWithLevel = (row, level) => {
  const rowEx = { ...row, level }
  return [rowEx, ...(rowEx.suborders?.map((o) => recurseWithLevel(o, level + 1)) || [])]
}

const flattenRows = (data) => data.map((row) => recurseWithLevel(row, 0)).flat(Infinity)

/**
 *
 * @param {OrderShape[]} data
 * @param {Function} t - translation function
 */
const convertDataToRows = (data, t) => {
  const transformedData = flattenRows(data).map((row) => [
    ...Object.entries(EXPORT_FIELDS).map(([name, transformFunc]) => {
      const { progressPercent, totalPlannedTime, totalSpentTime } = calculateOrderProgress(row)

      return transformFunc({
        t,
        row,
        value: row[name],
        progress: {
          progressPercent,
          totalPlannedTime,
          totalSpentTime,
        },
      })
    }),
    ...transformOperations({ row, t }),
  ])

  return transformedData
}

/**
 *
 * @param {Object} titles - mapping of field names to translatable titles
 * @param {OrderShape[]} data
 * @param {String} sheetTitle - translatable sheet title
 * @param {Function} t - translation function
 */
export const exportToXLSX = ({ titles, data, sheetTitle, t }) => {
  const header = [
    ...Object.keys(EXPORT_FIELDS).map((field) => titles[field] ?? MORE_TITLES[field]({ t })),
    ...Object.values(OP_FIELD_TITLES).map((f) => f({ t })),
  ]
  const transformedData = [header, ...convertDataToRows(data, t)]
  const ws = XLSX.utils.aoa_to_sheet(transformedData)
  // const filledCells = ws['!ref']
  // const range = XLSX.utils.decode_range(filledCells)
  // for (let R = range.s.r; R <= range.e.r; ++R) {
  //   for (let C = range.s.c; C <= range.e.c; ++C) {
  //     const cellAddress = { c: C, r: R }
  //     /* if an A1-style address is needed, encode the address */
  //     const cellRef = XLSX.utils.encode_cell(cellAddress)
  //     console.log("CELL", cellRef, ws[cellRef])
  //     try {
  //       const {v} = ws[cellRef]
  //       ws[cellRef].t = 's'
  //       ws[cellRef].v = v
  //     } catch (e) {
  //       console.log(`Cell ${cellRef} does not exist`)
  //     }
  //   }
  // }
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetTitle || t('Production overview'))
  /* generate XLSX file and send to client */
  const now = new Date()
  now.setMilliseconds(0)
  const dateString = now.toISOString().replace(/[^\dT]/, '')
  XLSX.writeFile(wb, `ProductionOverview-${dateString}.xlsx`)
}
