import moment from 'moment'

export const eqQuery = (field, value) => {
  value = value?.trim()
  if (!value) {
    return {}
  }
  return {
    [`where.${field}`]: `${field}=${value}`,
  }
}

export const textQuery = (field, value) => {
  value = value?.trim()
  if (!value) {
    return {}
  }
  const meta = field.toUpperCase()
  return {
    [`where.${field}`]: `ilike(${field},${meta})`,
    [meta]: `%${value}%`,
  }
}

export const sernrPatternQuery = (field, value) => {
  value = value?.trim()
  if (!value) {
    return {}
  }
  const meta = field.toUpperCase()
  return {
    [`where.${field}`]: `like(str(${field}),${meta})`,
    [meta]: `%${value}%`,
  }
}

/**
 * 3 possible values of status - all, not started, problem
 * Also note: finished top level orders are excluded ALWAYS by default, see the `fetchTopLevelOrders()` function.
 */
export const statusQuery = (field, value) => {
  let q
  if (value === 'problem') {
    q = 'problem=true'
  } else if (value === 'not started') {
    q = 'started=false'
  }
  if (q) {
    return {
      [`where.${field}`]: q,
    }
  }
  return {}
}

/**
 *
 * @param field
 * @param value - a date string 'YYYY-MM-DD', or a colon-separated pair of date strings 'YYYY-MM-DD:YYYY-MM-DD'
 *                (either end can be empty)
 * @returns {{}}
 */
export const dateRangeQuery = (field, value) => {
  const query = {}
  let from
  let to
  let eq
  if (value?.includes(':')) {
    ;[from, to] = value.split(':')
  } else {
    eq = value
  }
  if (from) {
    const meta = `${field.toUpperCase()}_FROM`
    query[`where.${field}_from`] = `${field}>=${meta}`
    query[meta] = `${from}`
  }
  if (to) {
    const meta = `${field.toUpperCase()}_TO`
    query[`where.${field}_to`] = `${field}<=${meta}`
    query[meta] = `${to}`
  }
  if (eq) {
    const meta = `${field.toUpperCase()}_EQ`
    query[`where.${field}`] = `${field}=${meta}`
    query[meta] = `${eq}`
  }
  return query
}

export const operationsQuery = (field, value) => {
  value = value?.trim()
  if (!value) {
    return {}
  }
  const meta = field.toUpperCase()
  return {
    [`where.${field}`]: `ilike(str(reportings_json),${meta})`,
    [meta]: `%${value}%`,
  }
}

export const namedDatesQuery = (period) => {
  // note the separation by colon (from:to, "from" is empty here)
  let value
  const now = moment()
  if (period === 'today') {
    value = now.format('YYYY-MM-DD')
  } else if (period === 'tomorrow') {
    value = now.add(1, 'days').format('YYYY-MM-DD')
  } else if (period === 'this_week') {
    value = now.endOf('week').add(1, 'd').format('YYYY-MM-DD')
  } else if (period === 'this_month') {
    value = now.endOf('month').format('YYYY-MM-DD')
  }
  return `:${value}`
}

export const fromDateRangeQuery = (value) => {
  let from = ''
  let to = ''
  if (value?.includes(':')) {
    ;[from, to] = value.split(':')
  }
  return { from, to }
}

/**
 * Map of fields to their corresponding query builder functions
 */
export const FIELDS_TO_QUERY = {
  sernr: sernrPatternQuery,
  article: textQuery,
  part_desc: textQuery,
  prod_type: textQuery,
  end_client_name: textQuery,
  mt_sernr: sernrPatternQuery,
  planned_delivery: dateRangeQuery,
  planned_end_date: dateRangeQuery,
  need_date: dateRangeQuery,
  progress: statusQuery,
  operations: operationsQuery,
}

export const buildFilterQuery = (filterFields) => {
  let query = {}
  try {
    Object.entries(filterFields).forEach(([field, value]) => {
      query = { ...query, ...FIELDS_TO_QUERY[field](field, value) }
    })
  } catch (e) {
    e.fields = Object.entries(filterFields)
    throw e
  }
  return query
}
