import React from 'react'

import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { Check, ErrorOutline, PriorityHigh } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { OperationShape } from '../mainview/types'
import { progressStyles } from '../../themes/progress'
import { displayTime } from '../mainview/utils'
import { getOpFeatures } from '../common'

const makeLocalStyles = makeStyles({
  h3: {
    marginBottom: '0.5em',
    fontSize: '120%',
  },
})

/**
 * The Operation box component
 *
 * @param {OperationShape} op
 * @param lotSize
 * @returns {JSX.Element}
 * @constructor
 */
const Operation = ({ op, lotSize }) => {
  const { t } = useTranslation()
  const styles = progressStyles()
  const localStyles = makeLocalStyles()
  const statusClass = op.operation_finished ? styles.done : op.started ? styles.inProgress : styles.none

  const { reported, problem, allMaterialsCount, problemObj } = getOpFeatures(op)

  return (
    <Tooltip
      interactive
      title={
        <Box className="OperationTooltip">
          <Typography variant="h3" className={localStyles.h3}>
            {op.op_name}
          </Typography>
          {problemObj.missingMaterialsCount && (
            <div className="problem">
              <ErrorOutline className={styles.riskSign} />
              <span>
                {problemObj.missingMaterial
                  ? t('Material {material} missing').replace('{material}', problemObj.missingMaterial)
                  : t('{numMissing} / {numTotal} materials missing')
                      .replace('{numMissing}', problemObj.missingMaterialsCount)
                      .replace('{numTotal}', allMaterialsCount)}
              </span>
            </div>
          )}
          {problemObj.isLate && (
            <div className="problem">
              <ErrorOutline className={styles.riskSign} />
              <span>{t('Late')}</span>
            </div>
          )}
          {op.op_planned_start && (
            <Typography>
              {t('Planned start date:')} {op.op_planned_start}
            </Typography>
          )}
          <Typography>
            {t('Status:')} {op.operation_finished ? t('Finished') : op.started ? t('In progress') : t('Not started')}
          </Typography>
          <Typography>
            {t('Time spent:')} {displayTime(op.time_spent || 0)}
          </Typography>
          <Typography>
            {t('Time required:')} {displayTime(op.planned_time_sec || 0)}
          </Typography>
        </Box>
      }
    >
      <Box className={`Operation ${statusClass} ${problem ? styles.risk : ''}`}>
        <div>{op.operation}</div>
        <div>{op.operation_finished ? <Check fontSize="small" /> : `${reported} / ${lotSize}`}</div>
        {problem ? (
          <div className="risk-sign">
            <PriorityHigh className={styles.riskSign} />
          </div>
        ) : null}
      </Box>
    </Tooltip>
  )
}

Operation.propTypes = {
  op: PropTypes.shape(OperationShape).isRequired,
  lotSize: PropTypes.number.isRequired,
}

export default Operation
