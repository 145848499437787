import React, { useCallback, useEffect, useState } from 'react'
import './Header.css'

import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import { useDispatch, useSelector } from 'react-redux'
import { HelpOutline, ToggleOff, ToggleOn } from '@material-ui/icons'
import i18next from 'i18next'
import {
  getTopLevelOrdersPage,
  setFilterButtons,
  selectFilterButtons,
  resetFilterFields,
} from '../mainview/mainViewSlice'
import flagEE from '../../icons/flags/ee.svg'
import flagEN from '../../icons/flags/gb.svg'
import { Export } from '../exportToXLSX/Export'

const AUTOREFRESH_TIMEOUT_SEC = 3 * 60
const AUTOREFRESH_KEY = 'true'
const AUTOREFRESH_ON = 'true'

const getAutoRefresh = () => window.localStorage.getItem(AUTOREFRESH_KEY) === AUTOREFRESH_ON

function Header() {
  const { t } = useTranslation()

  const LANGUAGE_TO_ICON = {
    en: flagEN,
    et: flagEE,
  }

  const CRITERIA = {
    planned_delivery: t('Delivery date'),
    need_date: t('Due date'),
    planned_end_date: t('Forecast prod. date'),
  }

  // historical name; now it is deadlines instead
  const PERIODS = {
    today: t('Today'),
    tomorrow: t('Tomorrow'),
    this_week: t('This week'),
    this_month: t('This month'),
  }

  const dispatch = useDispatch()
  const filterButtons = useSelector(selectFilterButtons)
  const [autoRefresh, setAutoRefresh] = useState(getAutoRefresh())

  useEffect(() => {
    setTimeout(() => {
      if (getAutoRefresh()) {
        window.location.reload()
      }
    }, AUTOREFRESH_TIMEOUT_SEC * 1000)
  }, [])

  const toggleAutoRefresh = useCallback(() => {
    const newValue = !autoRefresh
    if (newValue) {
      window.localStorage.setItem(AUTOREFRESH_KEY, AUTOREFRESH_ON)
    } else {
      window.localStorage.removeItem(AUTOREFRESH_KEY)
    }
    setAutoRefresh(newValue)
  }, [autoRefresh])

  const togglePeriod = useCallback(
    (name) => {
      const { criterion, period } = filterButtons
      let newPeriod
      if (name === null || period === name) {
        newPeriod = null
      } else {
        newPeriod = name
      }
      dispatch(setFilterButtons({ criterion, period: newPeriod }))
      dispatch(getTopLevelOrdersPage())
    },
    [dispatch, filterButtons],
  )

  const toggleCriterion = useCallback(
    (name) => {
      const { criterion, period } = filterButtons
      if (!criterion) {
        // selected a new criterion, no filters/data changes yet
        dispatch(setFilterButtons({ criterion: name }))
        return
      }

      if (criterion === name) {
        // unselected criterion => clear criterion and period
        dispatch(setFilterButtons())
      } else {
        // selected a criterion while another is selected => copy the previous period to the new criterion
        dispatch(setFilterButtons({ criterion: name, period }))
      }

      // if a period had been selected, reapply filters
      if (period) {
        dispatch(getTopLevelOrdersPage())
      }
    },
    [dispatch, filterButtons],
  )

  const clearFilters = useCallback(() => {
    dispatch(resetFilterFields())
    dispatch(getTopLevelOrdersPage())
  }, [dispatch])

  return (
    <Box className="Header">
      <Grid container spacing={3} direction="row-reverse">
        <Grid item md={4}>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip
                title={t('Autorefresh the page in {sec} seconds: {status}')
                  .replace('{sec}', AUTOREFRESH_TIMEOUT_SEC)
                  .replace('{status}', autoRefresh ? t('on') : t('off'))}
              >
                <Button
                  startIcon={<CachedIcon />}
                  color={autoRefresh ? 'primary' : 'secondary'}
                  className="Button"
                  variant="outlined"
                  onClick={toggleAutoRefresh}
                >
                  {t('off')}
                  {autoRefresh ? <ToggleOn color="primary" /> : <ToggleOff color="secondary" />}
                  {t('on')}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Export />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                className="Language"
                onClick={() => {
                  i18next.changeLanguage(i18next.language === 'en' ? 'et' : 'en')
                }}
              >
                <img src={LANGUAGE_TO_ICON[i18next.language]} alt={t('Switch language')} /> {t('Language')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="secondary" startIcon={<HelpOutline />}>
                {t('Help')}
              </Button>
            </Grid>
            <Grid item style={{ flexBasis: '100%' }}>
              <Button variant="outlined" color="secondary" onClick={clearFilters}>
                {t('Clear filters')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={8} justify="flex-start" spacing={2}>
          <Grid container item spacing={1} justify="flex-start" alignItems="center">
            <Grid item md={3}>
              <Typography align="left" color="secondary" className="Filter">
                {t('Criterion')}
              </Typography>
            </Grid>
            <Grid item md={9} container spacing={1}>
              {Object.entries(CRITERIA).map(([name, title]) => (
                <Grid item key={name}>
                  <Button
                    disableElevation
                    variant={filterButtons.criterion === name ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => toggleCriterion(name)}
                  >
                    {title}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={1} justify="flex-start" alignItems="flex-start">
            <Grid item md={3}>
              <Typography align="left" color="secondary" className="Filter">
                {t('Deadline')}
              </Typography>
            </Grid>
            <Grid item md={9} container spacing={1}>
              {Object.entries(PERIODS).map(([name, title]) => (
                <Grid item key={name}>
                  <Button
                    disableElevation
                    disabled={!filterButtons.criterion}
                    variant={filterButtons.period === name ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => togglePeriod(name)}
                  >
                    {title}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
