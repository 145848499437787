import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'
import i18nSettings from './i18nSettings.json'

import enTranslations from './locales/en/translations.json'
import etTranslations from './locales/et/translations.json'

const resources = {
  en: {
    // 'translations' is the default namespace from i18nSettings.
    translations: enTranslations,
  },
  et: {
    translations: etTranslations,
  },
}

const userLanguageToTranslation = {
  est: 'et',
  eng: 'en',
}

const userLanguageCookie = Cookies.get('userlang')
const userLanguage = userLanguageToTranslation[userLanguageCookie] || i18nSettings.DEFAULT_LANGUAGE

/**
 * A good place to start:
 * https://www.i18next.com/overview/configuration-options
 */
i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources,
    lng: userLanguage,
    fallbackLng: i18nSettings.FALLBACK_LANGUAGE,
    debug: false,
    returnEmptyString: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    nsSeparator: i18nSettings.NS_SEPARATOR,
    ns: i18nSettings.DEFAULT_NAMESPACE,
    defaultNS: i18nSettings.DEFAULT_NAMESPACE,
    supportedLngs: i18nSettings.LANGUAGE_ORDER,

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
    react: {
      // Currently Suspense is not server ready
      useSuspense: false,
    },
  })

export default i18n
