export const populateOrdersTree = (orders) => {
  const ordersById = new Map()
  // a separate operation to populate all orders by id is necessary because otherwise
  // if an order refers to a parent which isn't yet in the Map, it would be a problem.
  orders.forEach((o) => ordersById.set(o.sernr, o))
  orders.forEach((o) => {
    const parent = ordersById.get(o.parent)
    if (parent) {
      parent.suborders = parent.suborders || []
      parent.suborders.push(o)
    }
  })
}
