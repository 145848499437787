import { ordersRequest, treeRequest, treeWithTopLevelRequest } from './request'
import { populateOrdersTree } from './utils'

export const fetchTopLevelOrders = async ({ page = 1, query = null, rowsPerPage = 0 }) => {
  const queryLocal = {
    ...(query || {}),
    'where.parent': 'isnull(parent)',
    // ALWAYS exclude finished
    'where.status': query['where.status'] ? `and(${query['where.status']},finished=false)` : 'finished=false',
  }

  let response = await ordersRequest({ query: queryLocal, count: true })
  const { count } = response.data[0]

  if (count === 0) {
    response = { data: [] }
  } else {
    response = await ordersRequest({ page, query: queryLocal, rowsPerPage })
  }

  return {
    ...response,
    count,
  }
}

/**
 * Fetch suborders (all descendants) for the specified orders.
 *
 * This involves a request to the 'tree' endpoint and
 *  then populating the `suborders` attributes of orders.
 *
 * @param {OrderShape[]} orders
 * @returns {Promise<OrderShape[]>}
 */
export const fetchTree = async (orders, signal) => {
  const orderIds = orders.map((o) => o.sernr)

  const query = {
    where: `in(master,${orderIds.join(',')})`,
    limit: 10000,
  }

  const { data: ordersTree } = await treeRequest({ query, signal })

  if (!ordersTree.length) {
    return orders
  }

  const subordersIds = ordersTree.map((o) => o.sernr)

  const subordersQuery = {
    where: `in(sernr,${subordersIds.join(',')})`,
    limit: 10000,
  }

  const { data: suborders } = await ordersRequest({ query: subordersQuery, signal })

  populateOrdersTree([...orders, ...suborders])

  return orders
}

/**
 * Fetch an order and the branch that it belongs to.
 *
 * The sequence of actions:
 *  - fetch an order from the list endpoint
 *  - if it is found, and has a parent, find its master through the tree endpoint
 *  - find its (or master's) descendants through the tree endpoint,
 *  - fetch the list of descendants (including master if the found order is not a top level order itself)
 *
 * @param {number} orderId
 * @returns {Promise<OrderShape[]>}
 */
export const fetchBranch = async (orderId) => {
  const queryOrderById = {
    where: `sernr=${orderId}|master=${orderId}`,
    limit: 10000,
  }
  // if the order exists and is top level, the response contains the whole tree
  // otherwise, the response contains only the order itself
  const { data: maybeTree } = await treeWithTopLevelRequest({ query: queryOrderById })
  if (!maybeTree?.length) {
    return []
  }

  let orderIdsToFetch
  if (maybeTree.some((o) => !o.master)) {
    // orderId is a top level order, we already got the full tree.
    // NOTE: see the case below. One order can be TOP LEVEL and NOT TOP LEVEL at the same time :facepalm:
    orderIdsToFetch = maybeTree.map((o) => o.sernr)
  } else {
    // orderId is not a top level order, need to fetch the tree from its master
    // handle stupid cases where one order has >1 masters
    const masterIds = maybeTree.map((o) => o.master)
    const query = {
      where: `in(master,${masterIds.join(',')})`,
      limit: 10000,
    }
    const { data: matched } = await treeRequest({ query })
    orderIdsToFetch = [...matched.map((o) => o.sernr), ...masterIds]
  }

  const ordersQuery = {
    where: `in(sernr,${orderIdsToFetch.join(',')})`,
    limit: 10000,
  }

  const { data: orders } = await ordersRequest({ query: ordersQuery })
  populateOrdersTree(orders)
  return orders.filter((o) => !o.parent)
}
