import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import PropTypes from 'prop-types'
import React from 'react'

export const SwapVertButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button className="SwapVertButton" color="secondary" size="small" onClick={onClick} title={t('Toggle column')}>
      <SwapVertIcon color="secondary" />
    </Button>
  )
}

SwapVertButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
