import React from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFields } from '../common'
import { exportToXLSX } from './utils'
import { selectRows } from '../mainview/mainViewSlice'

export const Export = () => {
  const { t } = useTranslation()
  const fields = useFields()
  const data = useSelector(selectRows)
  const sheetTitle = t('Production overview')
  const titles = {
    ...fields,
    ordered: t('Ordered'),
    status: t('Status'),
    time_spent: t('Time spent'),
    time_planned: t('Time planned'),
    risk: t('Risk'),
  }

  return (
    <Button variant="outlined" color="secondary" onClick={() => exportToXLSX({ data, titles, sheetTitle, t })}>
      {t('Export')}
    </Button>
  )
}
