/*
 The PROGRESS BAR theme
 */
import { createMuiTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'

/* not that it's actually used... */
export const theme = createMuiTheme({
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      // the color of finished items
      main: '#75db45',
    },
    secondary: {
      // the color of items in progress
      main: '#ffa620',
    },
  },
})

export const progressStyles = makeStyles({
  bar: {
    backgroundColor: 'white',
    color: '#b0b0b0',
  },
  none: {
    backgroundColor: 'white',
  },
  done: {
    backgroundColor: '#75db45',
    color: 'white',
  },
  inProgress: {
    backgroundColor: '#ffa620',
    color: 'white',
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#909090',
    fontSize: '75%',
  },
  risk: {
    border: '2px solid red',
  },
  riskSign: {
    color: 'red',
  },
})
