import { Button } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'

export const ShowSubordersButton = ({ show, onClick }) => (
  <Button className="ShowSubordersButton" onClick={onClick}>
    {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
  </Button>
)

ShowSubordersButton.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
