import PropTypes from 'prop-types'

/**
 * @typedef {Object} MaterialShape
 * @property {String} material - name (symbol, article?)
 * @property {Number} needed_material_qty - how much is needed for the operation
 * @property {Number} inv_qty - how much is available
 */
export const MaterialShape = {
  material: PropTypes.string.isRequired,
  needed_material_qty: PropTypes.number.isRequired,
  inv_qty: PropTypes.number.isRequired,
}

/**
 * @typedef {Object} OperationShape
 * @property {String} operation - name (code)
 * @property {String} op_name - name (full)
 * @property {Number} jrk - sequence number
 * @property {Boolean} started
 * @property {Boolean} operation_finished
 * @property {Number} time_spent - seconds
 * @property {String} op_planned_start - date of planned start
 * @property {Number} planned_time_sec - seconds
 * @property {Number} wt_qty - number of processed parts ?
 * @property {Number} scrap - add up number of processed parts ?
 * @property {Boolean} problem - whether there is a problem with materials
 * @property {MaterialShape[]} materials - list of materials required for the operation
 */
export const OperationShape = {
  operation: PropTypes.string.isRequired,
  op_name: PropTypes.string.isRequired,
  jrk: PropTypes.number.isRequired,
  started: PropTypes.bool,
  operation_finished: PropTypes.bool,
  op_planned_start: PropTypes.string,
  time_spent: PropTypes.number,
  planned_time_sec: PropTypes.number,
  wt_qty: PropTypes.number,
  scrap: PropTypes.number,
  problem: PropTypes.bool,
  materials: PropTypes.arrayOf(PropTypes.shape(MaterialShape)),
}

/**
 * @typedef {Object} OrderShape
 * @property {Number} sernr - Order ID
 * @property {Number} mt_sernr - Sales Order ID
 * @property {Number} parent - Parent Order ID
 * @property {String} article
 * @property {String} prod_type
 * @property {String} part_desc
 * @property {Number} lot_size - Production Order quantity
 * @property {Number} received - Receiving SUM from Reporting to this Production Order
 * @property {String} end_client_name
 * @property {Boolean} started - whether the order is started
 * @property {Boolean} finished - whether the order is completed
 * @property {String} planned_delivery - delivery date
 * @property {String} planned_end_date - completion date
 * @property {String} need_date - deadline
 * @property {OrderShape[]} suborders
 * @property {OperationShape[]} reportings_json

 */
export const OrderShape = {
  sernr: PropTypes.number.isRequired,
  mt_sernr: PropTypes.number,
  parent: PropTypes.number,
  article: PropTypes.string.isRequired,
  prod_type: PropTypes.string.isRequired,
  part_desc: PropTypes.string.isRequired,
  lot_size: PropTypes.number,
  received: PropTypes.number,
  end_client_name: PropTypes.string.isRequired,
  started: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  planned_delivery: PropTypes.string,
  planned_end_date: PropTypes.string.isRequired,
  need_date: PropTypes.string.isRequired,
  reportings_json: PropTypes.arrayOf(PropTypes.shape(OperationShape)),
  suborders: PropTypes.array, // this will contain resolved suborders. Not validated
}
