import React, { useEffect } from 'react'
import './App.css'
import { ThemeProvider } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Header from './features/header/Header'
import MainView from './features/mainview/MainView'

import { mainTheme } from './themes'

function App() {
  const { t } = useTranslation()
  useEffect(
    () => {
      const eventListener = (e) => {
        if (e.ctrlKey && e.key.toUpperCase() === 'R') {
          window.location.reload()
        }
      }

      // Add event listener
      document.addEventListener('keydown', eventListener)

      // Remove event listener on cleanup
      return () => {
        document.removeEventListener('keydown', eventListener)
      }
    },
    [], // Re-run if eventName or element changes
  )
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>{t('Production overview')}</title>
      </Helmet>
      <div className="App">
        <Header />
        <MainView />
      </div>
    </ThemeProvider>
  )
}

export default App
