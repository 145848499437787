import { useTranslation } from 'react-i18next'

export const useFields = () => {
  const { t } = useTranslation()

  return {
    sernr: t('Product order'),
    article: t('Article'),
    part_desc: t('Product Name'),
    prod_type: t('Product Type'),
    end_client_name: t('Customer'),
    mt_sernr: t('Sales order no.'),
    planned_delivery: t('Delivery date'),
    need_date: t('Due date'),
    produced: t('Produced'),
    to_produce: t('To Produce'),
    progress: t('Progress'),
    operations: t('Operations'),
  }
}
