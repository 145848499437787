import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchBranch, fetchTopLevelOrders, fetchTree } from '../../api'
import { ITEMS_PER_PAGE } from '../../api/config'
import { buildFilterQuery, namedDatesQuery } from './fields'

const DEFAULT_FILTER_BUTTONS = {
  criterion: null,
  period: null,
}

const DEFAULT_FILTER_FIELDS = {
  sernr: '',
  article: '',
  part_desc: '',
  end_client_name: '',
  mt_sernr: '',
  need_date: null,
  planned_delivery: null,
  planned_end_date: null,
  progress: '',
}

const DEFAULT_FILTER_BUTTONS_CRITERIA = { need_date: null, planned_delivery: null, planned_end_date: null }

const initialState = {
  /** @type {OrderShape[]} */
  topLevelOrders: [],
  topLevelOrdersCount: 0,
  topLevelOrdersPage: 1,
  rowsPerPage: ITEMS_PER_PAGE, // use the default
  filterButtons: { ...DEFAULT_FILTER_BUTTONS },
  filterFields: { ...DEFAULT_FILTER_FIELDS },
  isLoading: false,
  error: null,
}

export const getTopLevelOrdersPage = createAsyncThunk(
  'mainView/getTopLevelOrdersPage',
  async ({ page = null } = {}, { getState, rejectWithValue, signal }) => {
    try {
      const { rowsPerPage, topLevelOrdersPage, filterFields } = getState().mainView
      page = page || topLevelOrdersPage
      const query = buildFilterQuery(filterFields)
      const response = await fetchTopLevelOrders({ page, rowsPerPage, query, signal })
      const { count, data } = response
      if (count && !signal.aborted) {
        await fetchTree(data, signal)
      }
      // The value we return becomes the `fulfilled` action payload
      return { ...response, page } // => {count, data}
    } catch (e) {
      return rejectWithValue(e.toString())
    }
  },
)

export const getOrderById = createAsyncThunk('mainView/getOrderById', async ({ orderId }, { rejectWithValue }) => {
  try {
    const orders = await fetchBranch(orderId)
    // The value we return becomes the `fulfilled` action payload
    // Since we find an exact match, it is always a count of one,
    // though there can be other orders in the subtree
    return { data: orders, page: 1, count: orders?.length ? 1 : 0 }
  } catch (e) {
    return rejectWithValue(e.toString())
  }
})

export const mainViewSlice = createSlice({
  name: 'mainView',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPage: (state, action) => {
      state.topLevelOrdersPage = action.payload
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    },
    setFilterFields: (state, action) => {
      state.filterFields = { ...state.filterFields, ...action.payload }
    },
    resetFilterFields: (state, _action) => {
      state.filterButtons = { ...DEFAULT_FILTER_BUTTONS }
      state.filterFields = { ...DEFAULT_FILTER_FIELDS }
    },
    setFilterButtons: (state, action) => {
      const { criterion, period } = action.payload || {}
      const criteria = { ...DEFAULT_FILTER_BUTTONS_CRITERIA }
      if (criterion && period) {
        criteria[criterion] = namedDatesQuery(period)
      }
      state.filterButtons = { ...DEFAULT_FILTER_BUTTONS, criterion, period }
      state.filterFields = { ...state.filterFields, ...criteria }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) =>
    builder
      .addCase(getTopLevelOrdersPage.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getTopLevelOrdersPage.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        const { data, count, page } = action.payload
        state.topLevelOrdersPage = page
        state.topLevelOrdersCount = count
        state.topLevelOrders = data
      })
      .addCase(getTopLevelOrdersPage.rejected, (state, action) => {
        state.isLoading = false
        if (action.error?.name === 'AbortError') {
          return
        }
        /* eslint-disable-next-line no-console */
        console.error('An error occurred while fetching a list of orders', action)
        state.error = action.payload || 'There was an error getting the data'
      })
      .addCase(getOrderById.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        const { data, count, page } = action.payload
        state.topLevelOrdersPage = page
        state.topLevelOrdersCount = count
        state.topLevelOrders = data
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.isLoading = false
        if (action.error?.name === 'AbortError') {
          return
        }
        /* eslint-disable-next-line no-console */
        console.error('An error occurred while fetching an order by id', action.payload)
        state.error = action.payload || 'There was an error getting the data'
      }),
})

export const { setRowsPerPage, setPage, setFilterButtons, setFilterFields, resetFilterFields } = mainViewSlice.actions

export const selectError = (state) => state.mainView.error
/**
 * Order rows selector
 *
 * @param state
 * @returns {OrderShape[]|*}
 */
export const selectRows = (state) => state.mainView.topLevelOrders
export const selectIsLoading = (state) => state.mainView.isLoading
export const selectPagination = (state) => ({
  page: state.mainView.topLevelOrdersPage,
  count: state.mainView.topLevelOrdersCount,
  rowsPerPage: state.mainView.rowsPerPage,
})
export const selectFilterButtons = (state) => state.mainView.filterButtons
export const selectFilterFields = (state) => state.mainView.filterFields

export default mainViewSlice.reducer
