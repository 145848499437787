/* global nw */
import { metaHost } from '../../api/config.default'

const PATH = 'static/webix/modules/ProdOrder/index.html'

export const openWin = (query, params) => {
  if (!nw) {
    return false
  }
  const qs = query ? new URLSearchParams(query) : ''
  const openUrl = `${metaHost}${PATH}?${qs}`

  let isOpen = false

  const icb = (w) => {
    if (!isOpen) {
      w.show()
      w.focus()
      isOpen = true
    }
  }

  nw.Window.open(openUrl, params || {}, (w) => {
    w.on('loaded', (_) => {
      icb(w, 'from loaded')
    })
    w.on('document-end', (_) => {
      icb(w, 'from document-end')
    })
    w.window.onload = () => {
      icb(w, 'from onload')
    }
  })
  return false
}
