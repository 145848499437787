import React from 'react'
import './ProgressBar.css'

import { Box, Typography, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorOutline } from '@material-ui/icons'
import { progressStyles } from '../../themes/progress'
import { OrderShape } from '../mainview/types'
import { calculateOrderProgress, displayTime } from '../mainview/utils'
import { getProblemOps } from '../common/operations'

const ProgressBar = ({ row, toggleOperationsColumn }) => {
  const { t } = useTranslation()
  const styles = progressStyles()

  const { progressPercent: progress, totalPlannedTime, totalSpentTime } = calculateOrderProgress(row)
  const className = progress === 0 ? styles.none : progress === 100 ? styles.done : styles.inProgress
  const problemOps = progress < 100 && getProblemOps(row.reportings_json ?? [])
  const problem = problemOps.length

  return (
    <Tooltip
      interactive
      title={
        <Box className="ProgressTooltip">
          <Typography variant="h4" paragraph>
            {t('Order #')} {row.sernr}
          </Typography>
          <Typography>
            {t('Status:')} {progress === 0 ? t('Not started') : progress === 100 ? t('Finished') : t('In progress')}
          </Typography>
          <Typography>
            {t('Time spent:')} {displayTime(totalSpentTime)}
          </Typography>
          <Typography>
            {t('Time required:')} {displayTime(totalPlannedTime)}
          </Typography>
          {row.reportings_json?.map((op) => (
            <Typography key={op.jrk}>
              + {op.operation} {displayTime(op.planned_time_sec || 0)}
            </Typography>
          ))}
        </Box>
      }
    >
      <Box className="ProgressBar-wrapper">
        {problem ? (
          <Box className={`risk ${progress > 70 ? 'left' : 'right'}`} onClick={toggleOperationsColumn}>
            <Tooltip
              interactive
              placement="top"
              title={
                problemOps.length === 1
                  ? (problemOps[0].missingMaterialsCount
                      ? t('Operation {opcode} is missing required material')
                      : t('Operation {opcode} is late')
                    ).replace('{opcode}', problemOps[0].operation)
                  : t('There is risk with some operations')
              }
            >
              <ErrorOutline className={styles.riskSign} />
            </Tooltip>
          </Box>
        ) : null}
        <Box className={`ProgressBar ${styles.bar}`}>
          <div className={className} style={{ flexBasis: `${progress}%` }}>
            {progress > 33 && <Typography className="value">{progress}%</Typography>}
          </div>
          <div className="leftover">{progress <= 33 && <Typography className="value">{progress}%</Typography>}</div>
        </Box>
      </Box>
    </Tooltip>
  )
}

ProgressBar.propTypes = {
  row: PropTypes.shape(OrderShape).isRequired,
  toggleOperationsColumn: PropTypes.func.isRequired,
}

export default ProgressBar
